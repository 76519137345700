import envDev from './dev'
import envLocal from './local'
import envProd from './prod'

export type Env = {
  AWS: {
    Auth: {
      region: string
      userPoolId: string
      userPoolWebClientId: string
      identityPoolId: string
      authenticationFlowType: string
      mandatorySignIn: boolean
    }
    API: {
      endpoints: {
        name: string
        endpoint: string
        custom_header?: (h: any) => Promise<{ Authorization: string }>
      }[]
    }
  }
  SENTRY: {
    DSN: string
  }
}

const getEnvironmentVariables = (): Env => {
  if (process.env.REACT_APP_ENV === 'local') { return envLocal }
  if (process.env.REACT_APP_ENV === 'dev') { return envDev }
  if (process.env.REACT_APP_ENV === 'prod') { return envProd }
  return envDev
}

export const isNotRunningLocally = () =>
  (process.env.REACT_APP_ENV !== undefined && process.env.REACT_APP_ENV !== 'local')
  
const env = getEnvironmentVariables()

export default env
