import { useEffect, useState } from 'react'
import { ReactComponent as Logo } from 'src/shared/assets/logo.svg'
import { Button, NavLinkButton } from '../Button/index'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import { SidebarWrapper, NavItem, TalkItTitle } from './index.styles'
import { Link } from '../Text'
import { getSidebarRoutes } from 'src/shared/routes'
import { colors } from 'src/shared/colors'
import { checkIsPluginAdmin } from 'src/shared/aws'

function Sidebar (props) {
  const [navOpen, setNavOpen] = useState(true)
  const [isPluginAdmin, setIsPluginAdmin] = useState(false)

  const handleClick = () => {
    setNavOpen(!navOpen)
  }

  const navClass = navOpen ? 'ExpandedNav' : 'MinimizedNav'
  const navItemClass = navOpen ? ['expandedButton'] : ['iconButton']
  const collapseControlClass = navOpen ? ['expandedButton'] : ['iconButton', 'minimizeButton']
  const collapseControlIcon = navOpen ? mdiChevronLeft : mdiChevronRight

  useEffect(() => {
    const getIsPluginAdmin = async () => {
      setIsPluginAdmin(await checkIsPluginAdmin())
    }
    getIsPluginAdmin()
  }, [])

  return (
    <SidebarWrapper {...props}>
      <nav className={navClass}>
        <TalkItTitle className="talkItTitle">
          <Link to="/">
            <Logo />
            {navOpen && <h1>ConnectIt+<span className="online">{isPluginAdmin ? 'Admin' : 'Developer'}</span></h1>}
          </Link>
        </TalkItTitle>
        <ul>
          {getSidebarRoutes(isPluginAdmin).map((item, index) => (
            <NavItem key={index} title={item.title}>
              <NavLinkButton className={navItemClass} data-testid={item.title} iconColor={colors.BLACK} iconName={item.icon} iconSize={1.25} to={item.to} text>{navOpen && item.title}</NavLinkButton>
            </NavItem>
          ))}
        </ul>
        <Button className={['toggleButton', ...collapseControlClass]} data-testid="collapseControl" iconColor={colors.BLACK} iconName={collapseControlIcon} onClick={handleClick} text>
          {navOpen && 'Minimize'}
        </Button>
      </nav>
    </SidebarWrapper>
  )
}

export default Sidebar
