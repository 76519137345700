import { useState, useEffect } from 'react'
import Checkbox from 'src/shared/components/Checkbox'
import Input from 'src/shared/components/Input'
import { getEmailFromLocationState, getParamsFromLocationSearch } from 'src/shared/extractors'
import { Error } from 'src/shared/components/Text'
import { SignInContainer, LogoImage, ButtonContainer, InputContainer, ButtonWrapper, OptionsContainer } from './index.styles'
import { Button, LinkButton } from 'src/shared/components/Button'
import connectItLogo from 'src/shared/assets/connect-it-logo.png'
import { Redirect } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useAuthContext } from 'src/shared/context/AuthProvider'
import LoadingSpinner from 'src/shared/components/LoadingSpinner'
import { log } from 'src/shared/logger'
import {EventKind} from 'src/shared/events'
import { useNotificationContext } from 'src/shared/context/NotificationProvider'
import { formatLowercase } from 'src/shared/formatters'

function SignIn (props) {
  const { email_address: email, confirmed: isConfirmed, message: confirmationType } = getParamsFromLocationSearch(props)
  const initialFieldState = { email: getEmailFromLocationState(props) || email, password: '' }
  const [field, setField] = useState(initialFieldState)
  const [loading, setLoading] = useState(false)
  const [signedInError, setSignedInError] = useState(false)
  const [noAdminAccessError, setNoAdminAccessError] = useState(false)
  const { currentUser, signIn } = useAuthContext()
  const [rememberUser, setRememberUser] = useState(true)
  const [rememberUserCheckbox, setRememberUserCheckbox] = useState(true)
  const [displayedConfirmation, setDisplayedConfirmation] = useState(false)
  const { sendSuccessNotification, sendErrorNotification } = useNotificationContext()

  useEffect(() => {
    const authUser = async () => {
      if (!currentUser && getRememberUserOnDevice() === 'true') {
        try {
          await signIn(await Auth.currentAuthenticatedUser())
        } catch (error) {
          setRememberUserOnDevice(false)
        }
      }
    }
    authUser()

    if (getRememberUserOnDevice() === 'false') {
      setRememberUserCheckbox(false)
      setRememberUser(false)
    }
  }, [currentUser, isConfirmed, sendSuccessNotification, signIn])

  const updateField = (event) => {
    setField({ ...field, [event.target.name]: event.target.value })
  }

  const isFormValid = () => {
    const fields = Object.keys(field)
    const hasAllFieldsFilled = fields.every(fieldName => field[fieldName] !== '')
    return hasAllFieldsFilled
  }

  const verifyIsPortalUser = async (user) => {
    const groups = user.signInUserSession.accessToken.payload['cognito:groups']
    if (!groups?.includes('PluginAdmin') && !groups?.includes('PluginDev')) {
      await Auth.signOut()
      setLoading(false)
      setNoAdminAccessError(true)
      return false
    }
    return true
  }

  const requestCognitoLogin = async () => {
    if (isFormValid()) {
      setLoading(true)
      setRememberUserOnDevice(rememberUser)

      try {
        Auth.configure({ storage: rememberUser ? localStorage : sessionStorage })
        const user = await Auth.signIn(formatLowercase(field.email), field.password)
        if (!await verifyIsPortalUser(user)) return
        setSignedInError(false)
        await signIn(user)
        log(EventKind.UserSignedIn)
        setLoading(false)
      } catch (error) {
        await Auth.signOut()
        setLoading(false)
        setSignedInError(true)
      }
    }
  }

  const toggleRememberUser = () => {
    if (rememberUserCheckbox === false) {
      setRememberUserCheckbox(true)
      setRememberUser(true)
    } else {
      setRememberUserCheckbox(false)
      setRememberUser(false)
    }
  }

  const getRememberUserOnDevice = () => {
    return localStorage.getItem('rememberUserOnDevice')
  }

  const setRememberUserOnDevice = (rememberUser) => {
    localStorage.setItem('rememberUserOnDevice', rememberUser)
  }

  const enterSubmit = (event) => {
    if (event.key === 'Enter' && isFormValid()) {
      requestCognitoLogin()
    }
  }

  if (currentUser) {
    return <Redirect to="/" />
  }

  if (shouldDisplayConfirmation() && !displayedConfirmation) {
    displayConfirmationNotification()
    setDisplayedConfirmation(true)
  }

  return (
    <SignInContainer>
      <LogoImage alt="ConnectIt+ Logo" src={connectItLogo}></LogoImage>
      <InputContainer>
        <Input
          label="Account Email"
          name="email"
          onChange={updateField}
          onKeyPress={enterSubmit}
          placeholder="What's your email?"
          type="email"
          value={field.email || ''} />
        <Input
          label="Password"
          name="password"
          onChange={updateField}
          onKeyPress={enterSubmit}
          placeholder="Pssst, what’s your password?"
          type="password"
          value={field.password || ''} />
        {signedInError && <Error>Sign in unsuccessful, incorrect username or password.</Error>}
        {noAdminAccessError && <Error>You do not have access to this site,<br /> contact support for help.</Error>}
        <OptionsContainer>
          <Checkbox isChecked={rememberUserCheckbox} label="Remember Me" name="rememberUser" onChange={toggleRememberUser} />
          <LinkButton id="forgotpassword" to={{ pathname: '/forgotpassword', state: { email: field.email } }} inline>Forgot Password</LinkButton>
        </OptionsContainer>
      </InputContainer>
      <ButtonContainer>
        <ButtonWrapper>
          <Button disabled={!isFormValid()} id="signin" onClick={requestCognitoLogin}>
            {!loading && <span>Sign In</span>}
            {loading && <LoadingSpinner withoutPadding />}
          </Button>
        </ButtonWrapper>
      </ButtonContainer>
    </SignInContainer>
  )

  function displayConfirmationNotification () {
    if (isConfirmed === 'true') {
      sendSuccessNotification({ title: 'Account confirmed successfully!' })
    } else if (isConfirmed === 'false' && confirmationType === 'exists') {
      sendSuccessNotification({ title: 'Account already confirmed!' })
    } else if (isConfirmed === 'false' && confirmationType === 'expired') {
      sendErrorNotification({ title: 'Confirmation link expired, we just sent a new one to your email' })
    } else {
      sendErrorNotification({ title: 'Account not confirmed, please contact support for help' })
    }
  }

  function shouldDisplayConfirmation () {
    return isConfirmed === 'true' || isConfirmed === 'false'
  }
}

export default SignIn
