import Icon from '@mdi/react'
import { H4 } from '../Text'
import { LabelWrapper, Container, RadioIcon } from './index.styles'
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js'
import { colors } from 'src/shared/colors'

interface RadioButtonProps {
  label: string
  isSelected: boolean
  name: string
  onSelected?: (name: string) => void
}

const RadioButton = ({ label, isSelected, name, onSelected, ...props }: RadioButtonProps) => {
  const onClickHandler = () => {
    if (onSelected) onSelected(name)
  }

  return (
    <Container onClick={onClickHandler}>
      <LabelWrapper>
        <RadioIcon>
          {isSelected
            ? <Icon data-testid='rb_selected' {...props} className="mainIcon" color={colors.BLUE} path={mdiRadioboxMarked} size={1}></Icon>
            : <Icon data-testid='rb_unselected' {...props} className="mainIcon" color={colors.BLUE} path={mdiRadioboxBlank} size={1}></Icon>
          }
        </RadioIcon>
        <H4 noSpace>{label}</H4>
      </LabelWrapper>
    </Container>
  )
}

export default RadioButton
