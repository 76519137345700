import styled from 'styled-components'
import { colors } from 'src/shared/colors'

export const ModalBackground = styled.div`
    position: fixed;
    z-index: 999999;
    background: ${colors.BLACK_A40};
    backdrop-filter: blur(4px);
    width: 100%;
    height: 100%;
    padding: 1rem;
    overflow-y: auto;
    top: 0;
    left: 0;
`

export const ModalBackgroundContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 100%;
`

export const ModalContainer = styled.div`
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    color: ${colors.BLACK};
    background-color: ${colors.WHITE};
    box-shadow: 0px .625rem 1.5rem ${colors.BLACK_A20};
    border-radius: .625rem;
    display: flex;
    flex-flow: column;
    padding: 1.5rem;
    width: 100%;
    max-width: 450px;
    overflow-x: hidden;
`
export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
        padding: .25rem;
        min-height: 2.5rem;
        min-width: 2.5rem;
    }
`

export const ModalTitle = styled.h3`
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
`
export const ModalBody = styled.div`
    display: flex;
    flex-flow: column;
    &> * {
        margin-top: .5rem;
    }
`

export const ModalDialogue = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
    
    a {
        margin-left: .5rem;
    }
`
