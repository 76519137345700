import styled from 'styled-components'
import { colors } from 'src/shared/colors'
import { media } from 'src/shared/breakpoints'

export const TabsContainer = styled.div`
    flex-direction: column;

    ${media.mobileNav} {
        display: none;
    }
`

export const TabList = styled.ul`
    display: flex;
    margin: 0;
    padding: 0 1.5rem;
    list-style: none;

    li {
        margin: 0 .5rem;
        position: relative;

        &:last-child {
            margin-right: 0;
        }

        &:first-child {
            margin-left: 0;
        }

        a {
            padding: 0 0.5rem 1rem .5rem;
            font-size: 1rem;
            display: flex;
            color: ${colors.BLACK};
        }
    }
`

export const TabBorder = styled.div`
    background: ${colors.GREY_200};
    width: 100%;
    height: 2px;
    border-radius: 2px;
`

export const ActiveBar = styled.div`
    background: ${colors.BLUE};
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    transform: translate(-.5rem, 2px);
`
