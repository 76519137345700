import React, { useState, useEffect } from 'react'
import Icon from '@mdi/react'
import { LabelWrapper, LabelText } from './index.styles'
import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from '@mdi/js'
import { colors } from 'src/shared/colors'

const Checkbox = ({ label, isChecked, name, onChange, ...props }) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  const onClickHandler = (event) => {
    if (onChange) onChange(name, !checked)
    setChecked(!checked)
  }

  return (
    <span>
      <LabelWrapper onClick={onClickHandler}>
        {checked
          ? <Icon data-testid='cb_checked' {...props} className="mainIcon" color={colors.BLUE} path={mdiCheckboxMarked } size={1}></Icon>
          : <Icon data-testid='cb_unchecked' {...props} className="mainIcon" color={colors.BLUE} path={mdiCheckboxBlankOutline} size={1}></Icon>
        }
        {label ? <LabelText>{label}</LabelText> : null}
      </LabelWrapper>
    </span>
  )
}

export default Checkbox
