import styled, { css } from 'styled-components'
import { colors } from 'src/shared/colors'

export const ContainerStyle = styled.div<{ outline?: boolean; maxHeight?: string, width?: 'full' }>`
    background: ${props => (props.outline ? colors.WHITE : colors.GREY_100)};
    border: ${props => (props.outline ? '2px solid' + colors.GREY_200 : 'none')};
    max-height: ${props => props.maxHeight || 'none'};
    position: relative;
    padding: .875rem;
    border-radius: .95rem;
    overflow-y: auto;

    ${props => props.width === 'full' && css`
        width: 100%;
    `}
`

export const ScrollSpace = styled.div`
    width: 100%;
    height: 1rem;
`

export const FloatingButton = styled.div`
    position: sticky;
    bottom: 0;
`
