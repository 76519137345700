import { Auth } from 'aws-amplify'
import { Env } from '.'

const constants: Env = {
  AWS: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_uzHmxAZ1m',
      userPoolWebClientId: '587l01ptbj4b0lqasov15akuaf',
      identityPoolId: 'us-east-1:5283646f-58cd-44a8-968f-8f9ecb8b30ee',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      mandatorySignIn: true
    },
    API: {
      endpoints: [
        {
          name: 'TALK_IT_API',
          endpoint: 'https://xb2aaj19gl.execute-api.us-east-1.amazonaws.com/prod'
        },
        {
          name: 'OPEN_API',
          endpoint: 'https://api.mobileassistant.us',
          custom_header: async (h) => {
            return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
          }
        },
      ]
    }
  },
  SENTRY: {
    DSN: 'https://b06c4d2911a248d8929aa315bae1e109@sentry.io/1406883'
  },
}

export default constants
