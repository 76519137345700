import { PluginInstallationStatus } from 'src/shared/api'
import { colors } from 'src/shared/colors'
import styled from 'styled-components'

export const PluginInstallationContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${colors.GREY_100};
  justify-content: space-between;
  
  &:hover {
    background-color: ${colors.GREY_100};
    cusor: pointer;
  }
  
  &:last-child {
    border-bottom: none;
  }
`

export const PluginInstallationStatusBadge = styled.div<{ status?: PluginInstallationStatus }>`
  background-color: ${colors.GREY_100};
  color: ${colors.BLACK};
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1rem;
  display: inline-block;

  ${(props) => props.status === PluginInstallationStatus.Uninstalled && `
    background-color: ${colors.RED};
    color: ${colors.WHITE};
  `}

  ${(props) => props.status === PluginInstallationStatus.Installed && `
    background-color: ${colors.GREEN};
    color: ${colors.WHITE};
  `}
`