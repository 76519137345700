import { Event } from "src/shared/api";
import Modal from "src/shared/components/Modal";
import { H4 } from "src/shared/components/Text";
import { useModalContext } from "src/shared/context/ModalProvider";
import { EventBadge } from "../EventList/index.styles";
interface EventDetailModalProps {
  event: Event,
}

function EventDetailModal({ event }: EventDetailModalProps) {
  const { closeModal } = useModalContext();

  return (
    <Modal title="Event detail" onClose={closeModal}>
      <H4>Event name</H4>
      <EventBadge style={{marginBottom: "1rem"}}>{event.event.toUpperCase()}</EventBadge>
      <H4>Created by</H4>
      <div>{event.actor}_id: {event.actorId}</div>
      <H4>Properties</H4>
      <textarea disabled style={{ width: "100%", height: "200px", marginBottom: "1rem" }} value={JSON.stringify(event.properties, null, 4)} />
      <H4>Created on</H4>
      <div>{event.createdOn}</div>
    </Modal>
  );
}

export default EventDetailModal;