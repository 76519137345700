import React from 'react'
import { ModalBackground, ModalContainer, ModalTitle, TitleWrapper, ModalBody, ModalDialogue, ModalBackgroundContainer } from './index.styles'
import { Button } from '../Button'
import { mdiClose } from '@mdi/js'
import { colors } from 'src/shared/colors'
import LoadingSpinner from '../LoadingSpinner'

interface ModalProps {
  title: string
  onClose?: () => void
  dialogue?: {
    secondary?: {
      title?: string
      onClick?: () => void
      loading?: boolean
    }
    primary?: {
      title?: string
      onClick?: () => void
      loading?: boolean
    }
  }
  children: React.ReactNode
}

function Modal (props: ModalProps) {
  const {
    secondary: dialogueSecondaryAction = {},
    primary: dialoguePrimaryAction = {}
  } = props.dialogue || {}

  return (
    <ModalBackground>
      <ModalBackgroundContainer>
        <ModalContainer {...props}>
          <TitleWrapper>
            <ModalTitle>{props.title}</ModalTitle>
            {props.onClose && <Button data-testid="close-modal" iconColor={colors.BLACK} iconName={mdiClose} onClick={props.onClose} small text />}
          </TitleWrapper>
          <ModalBody>{props.children}</ModalBody>
          {props.dialogue &&
          <ModalDialogue>
            {props.dialogue.secondary && <Button {...dialogueSecondaryAction} data-testid="dialogue-secondary-action" inverse>
              {dialogueSecondaryAction.loading ? <LoadingSpinner /> : dialogueSecondaryAction.title || 'Cancel'}
            </Button>}
            <Button {...dialoguePrimaryAction} data-testid="dialogue-primary-action">
              {dialoguePrimaryAction.loading ? <LoadingSpinner /> : dialoguePrimaryAction.title || 'Save'}
            </Button>
          </ModalDialogue>
          }
        </ModalContainer>
      </ModalBackgroundContainer>
    </ModalBackground>
  )
}

Modal.defaultProps = {
  secondaryButtonText: 'Cancel',
  actionButtonText: 'Save'
}

export default Modal
