import { API, Auth } from 'aws-amplify'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { logUserSignedIn, logUserSignedOut } from 'src/shared/logger'

import { getCognitoId } from 'src/shared/extractors'

interface AuthContextProps {
  loading: boolean
  currentUser: any
  signIn: (currentUser: any) => Promise<void>
  signOut: () => Promise<void>
  refreshUser: () => Promise<void>
  reloadUser: () => Promise<void>
}

export const AuthContext = createContext<AuthContextProps>({
  loading: true,
  currentUser: null,
  signIn: async () => {},
  signOut: async () => {},
  refreshUser: async () => {},
  reloadUser: async () => {}
})

const AuthProvider = props => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser()
        if (currentUser) {
          await signIn(currentUser)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchCurrentUser()
  }, [])

  const signIn = async (currentUser) => {
    const { talkItUser, talkItTeam } = await fetchUser(currentUser)
    setCurrentUser({ ...currentUser, talkItAttributes: { user: talkItUser, team: talkItTeam } })
    logUserSignedIn(talkItUser)
  }

  const signOut = async () => {
    logUserSignedOut()
    await Auth.signOut()
    setCurrentUser(null)
  }

  return <AuthContext.Provider value={{ loading, currentUser, signIn, signOut }} {...props} />
}

export const useAuthContext = () => useContext(AuthContext)

async function fetchUser (currentUser) {
  const cognitoId = getCognitoId(currentUser)
  const talkItUser = (await API.get('TALK_IT_API', `/user/${cognitoId}`, {})).data
  const { team_id: teamId } = talkItUser
  const talkItTeam = (await API.get('TALK_IT_API', `/team/${teamId}?cognito_id=${cognitoId}`, {})).data
  return { talkItUser, talkItTeam }
}

export default AuthProvider
