import React, { useState } from 'react'
import { getAccountRoutes } from 'src/shared/routes'
import { TabContainer, TabHeader, TabNavList, TabItems, Item, Divider, ItemLink } from './index.styles'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import Icon from '@mdi/react'
import { colors } from 'src/shared/colors'

function MobileTabNav () {
  const [isOpen, setIsOpen] = useState(false)
  const toggling = () => setIsOpen(!isOpen)
  const ACCOUNT_ROUTES_CONFIG = getAccountRoutes()

  return (
    <TabContainer>
      <TabHeader onClick={toggling}>
        <span>{ACCOUNT_ROUTES_CONFIG.filter((activeLink) => activeLink.to === window.location.pathname).map((activeLink) => activeLink.title)}</span>
        {isOpen ? <Icon color={colors.BLACK} path={mdiChevronUp} size={1} title="Upward Arrow" /> : <Icon color={colors.BLACK} path={mdiChevronDown} size={1} title="Downward Arrow"></Icon>}
      </TabHeader>
      {isOpen && (
        <TabNavList>
          <Divider/>
          <TabItems data-testid='dropdown-options'>
            {ACCOUNT_ROUTES_CONFIG.filter((activeLink) => activeLink.to !== window.location.pathname).map((item, index) => (
              <Item key={index}>
                <ItemLink to={item.to}>
                  {item.title}
                </ItemLink>
              </Item>
            ))}
          </TabItems>
        </TabNavList>
      )}
    </TabContainer>
  )
}

export default MobileTabNav
