import styled, { css } from 'styled-components'

interface ButtonGroupProps {
    width?: 'full' | 'auto'
}

export const ButtonGroup = styled.div<ButtonGroupProps>`
    display: flex;

    > *:first-child{
        margin-right: .5rem;
    }

    ${props => props.width === 'full' && css`
        width: 100%;
        justify-content: space-between;

        a {
            width: calc(50% - .25rem);
            min-width: fit-content;
        }
    `}
`
