import { useEffect, useState } from "react"
import { Event, PaginatedResponse, fetchEvents } from "src/shared/api"
import LoadingSpinner from "src/shared/components/LoadingSpinner"
import { EventContainer, EventBadge } from "./index.styles"
import HistoryPageLayout from "src/shared/components/HistoryPageLayout"
import { colors } from "src/shared/colors"
import { useModalContext } from "src/shared/context/ModalProvider"
import EventDetailModal from "../EventDetailModal"
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { EventKind } from "src/shared/events"


function EventList () {
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState<PaginatedResponse<Event> | null>(null)
  const [selectedEventType, setSelectedEventType] = useState<string | null>(null)

  const { openModal } = useModalContext()

  const loadMore = async () => {
    const lastId = events?.data[events?.data.length - 1].id
    setLoading(true)
    const result = await fetchEvents(lastId, selectedEventType)
    setEvents({ data: [...(events?.data ?? []), ...result.data], totalCount: result.totalCount, hasMore: result.hasMore })
    setLoading(false)
  }
  
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: events?.hasMore ?? false,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  useEffect(() => {
    const fetchApiRequest = async () => {
      const events = await fetchEvents(null, selectedEventType)
      console.log(events)
      setEvents(events)
      setLoading(false)
    }
    fetchApiRequest()
  }, [selectedEventType])

  const handleEventTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedEventType(event.target.value)
  }

  const eventTypes = Object.values(EventKind)

  if ((loading && events?.data.length === 0) || (loading && !events)) {
    return <LoadingSpinner />
  }

  return (
    <HistoryPageLayout ref={rootRef}>
      <select onChange={handleEventTypeChange}>
        <option value="">All Events</option>
        {eventTypes.map((eventType) => (
          <option key={eventType} value={eventType}>
            {eventType}
          </option>
        ))}
      </select>
      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "1.5rem", paddingBottom: "1rem", borderBottom: `2px solid ${colors.GREY_100}`}}>
        <div style={{fontWeight: "bold", flex: 1}}>Event name</div>
        <div style={{fontWeight: "bold", flex: 1}}>Created by</div>
        <div style={{fontWeight: "bold", flex: 1, textAlign: "right"}}>Created on</div>
      </div>
      {events?.data?.map((event) => (
        <EventContainer key={event.id} onClick={() => openModal(<EventDetailModal event={event} />)}>
          <div style={{flex: 1, textAlign: "left"}}>
            <EventBadge>{event.event.toUpperCase()}</EventBadge>
          </div>
          <div style={{flex: 1, textAlign: "left"}}>{event.actor}_id: {event.actorId}</div>
          <div style={{flex: 1, textAlign: "right"}}>{event.createdOn}</div>
        </EventContainer>
      ))}
      {events?.data?.length === 0 && <div style={{ marginTop: "1.5rem" }}>No events found.</div>}
      {(loading || events?.hasMore) && <div ref={sentryRef}><LoadingSpinner  /></div>}
    </HistoryPageLayout>
  )
}

export default EventList
