import { colors } from 'src/shared/colors'
import styled from 'styled-components'

export const EventContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid ${colors.GREY_100};
  justify-content: space-between;
  
  &:hover {
    background-color: ${colors.GREY_100};
    cusor: pointer;
  }
  
  &:last-child {
    border-bottom: none;
  }
`

export const EventBadge = styled.div`
  background-color: ${colors.GREY_100};
  color: ${colors.BLACK};
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1rem;
  display: inline-block;
`