import { Auth } from 'aws-amplify'
import { Env } from '.'

const constants: Env = {
  AWS: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_fyTd6mVO9',
      userPoolWebClientId: 'vom7ra3pkmv1nmrlhcq9lpvuj',
      identityPoolId: 'us-east-1:aabee669-c9de-4fb8-88b5-00a86299dc82',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      mandatorySignIn: true
    },
    API: {
      endpoints: [
        {
          name: 'TALK_IT_API',
          endpoint: 'https://dev-api.mobileassistant.us'
        },
        {
          name: 'OPEN_API',
          endpoint: 'https://dev-open-api.mobileassistant.us',
          custom_header: async (h) => {
            return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
          }
        },
      ]
    }
  },
  SENTRY: {
    DSN: 'https://b06c4d2911a248d8929aa315bae1e109@sentry.io/1406883'
  },
}

export default constants
