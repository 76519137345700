import { PluginStatus } from 'src/shared/api'
import { colors } from 'src/shared/colors'
import styled from 'styled-components'

export const PluginLogo = styled.div<{ logoUri?: string }>`
  ${(props) => props.logoUri && `
    background-image: url(${props.logoUri});
    background-size: contain;
  `}
  
  background-color: ${colors.BLACK_A20};
  width: 48px;
  height: 48px;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1.1rem;
  font-weight: bold;
`

export const PluginStatusBadge = styled.div<{ status?: PluginStatus }>`
  background-color: ${colors.GREY_100};
  color: ${colors.BLACK};
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1rem;
  display: inline-block;

  ${(props) => props.status === PluginStatus.Disabled && `
    background-color: ${colors.RED};
    color: ${colors.WHITE};
  `}

  ${(props) => props.status === PluginStatus.Published && `
    background-color: ${colors.GREEN};
    color: ${colors.WHITE};
  `}

  ${(props) => props.status === PluginStatus.Unlisted && `
    background-color: ${colors.ORANGE};
    color: ${colors.WHITE};
  `}
`

export const PluginContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-right: -1rem;
  margin-left: -1rem;
  border-bottom: 2px solid ${colors.GREY_100};
  justify-content: space-between;
  
  &:hover {
    background-color: ${colors.GREY_100};
    cusor: pointer;
  }
  
  &:last-child {
    border-bottom: none;
  }
`