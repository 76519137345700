import { useState } from "react";
import { getPluginStatus } from "src/plugins/utils";
import { Plugin, PluginStatus, updatePlugin } from "src/shared/api";
import Input from "src/shared/components/Input";
import { Label } from "src/shared/components/Input/index.styles";
import Modal from "src/shared/components/Modal";
import RadioButton from "src/shared/components/RadioButton";
import { useModalContext } from "src/shared/context/ModalProvider";
import { useNotificationContext } from "src/shared/context/NotificationProvider";
import { EventKind } from "src/shared/events";
import { log } from "src/shared/logger";

type PluginValues = {
  name: string
  description: string
  status: PluginStatus
  crmIntegrationId: string
}

type PluginErrors = {
  [key in keyof PluginValues]: string
}

interface EditPluginModalProps {
  refetch: () => void
  plugin: Plugin
}

function EditPluginModal({ refetch, plugin }: EditPluginModalProps) {
  const { closeModal } = useModalContext();
  const currentStatus = getPluginStatus(plugin)
  const [values, setValues] = useState<PluginValues>({
    name: plugin.name ?? '',
    description: plugin.description ?? '',
    status: currentStatus ?? '' as PluginStatus,
    crmIntegrationId: String(plugin.crmIntegrationId ?? '')
  })
  const [errors, setErrors] = useState<PluginErrors>({
    name: '',
    description: '',
    status: '',
    crmIntegrationId: ''
  })

  const statuses = Object.values(PluginStatus).map((status) => ({ label: status, value: status === values.status }))

  const { sendErrorNotification, sendSuccessNotification } = useNotificationContext()
  const [loading, setLoading] = useState(false)

  const create = async () => {
    const nextErrors: PluginErrors = {
      name: '',
      description: '',
      status: '',
      crmIntegrationId: ''
    }

    if (!values.name) {
      nextErrors.name = "Plugin name is required"
    }

    if (!values.description) {
      nextErrors.description = "Plugin description is required"
    }

    if (values.crmIntegrationId && isNaN(parseInt(values.crmIntegrationId))) {
      nextErrors.crmIntegrationId = "CRM Integration ID must be a number"
    }

    setErrors(nextErrors)
    
    if (!Object.entries(nextErrors).some(([_, value]) => value)) {
      try {
        setLoading(true)
        await updatePlugin({
          id: plugin.id,
          name: values.name,
          description: values.description,
          crmIntegrationId: parseInt(values.crmIntegrationId),
          disabled: values.status === PluginStatus.Disabled,
          published: values.status === PluginStatus.Published
        })
        sendSuccessNotification({ title: 'Plugin updated!' })
        log(EventKind.PluginUpdated, { pluginId: plugin.id })
        refetch()
        closeModal()
      } catch (e: any) {
        const title = e?.response?.data?.error || 'Something went wrong'
        sendErrorNotification({ title })
      } finally {
        setLoading(false)
      }
    }
  }

  const updateValues = (key: keyof PluginValues, value: string) => {
    setValues({ ...values, [key]: value })
    setErrors({ ...errors, [key]: '' })
  }

  return (
    <Modal title="Edit plugin" onClose={closeModal} dialogue={{ primary: { onClick: create, loading }, secondary: { onClick: closeModal }}}>
      <Input label="Plugin name" onChange={(e) => updateValues("name", e.target.value)} value={values.name} error={errors.name} />
      <Input label="Plugin description" onChange={(e) => updateValues("description", e.target.value)} value={values.description} error={errors.description} />
      <Input label="CRM Integration ID" onChange={(e) => updateValues("crmIntegrationId", e.target.value)} value={values.crmIntegrationId} error={errors.crmIntegrationId} />
      <Label>Plugin status</Label>
      {statuses.map(({ label, value }) => (
        <RadioButton key={label} label={label} isSelected={value} name={label} onSelected={(name) => updateValues("status", name)} />
      ))}
    </Modal>
  );
}

export default EditPluginModal;