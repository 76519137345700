import { getHumanReadablePluginPermissions, getPluginInstallationStatus } from "src/plugins/utils";
import { Plugin, PluginInstallation, PluginInstallationStatus, activatePluginInstallation, deactivatePluginInstallation } from "src/shared/api";
import { Button } from "src/shared/components/Button";
import Input from "src/shared/components/Input";
import Modal from "src/shared/components/Modal";
import { H4 } from "src/shared/components/Text";
import { useModalContext } from "src/shared/context/ModalProvider";
import { useNotificationContext } from "src/shared/context/NotificationProvider";
import { PluginInstallationStatusBadge } from "../index.styles";
import { log } from "src/shared/logger";
import { EventKind } from "src/shared/events";

interface EditPluginInstallationModalProps {
  plugin: Plugin,
  pluginInstallation: PluginInstallation
  refetch: () => void
}

function EditPluginInstallationModal({ plugin, pluginInstallation, refetch }: EditPluginInstallationModalProps) {
  const { closeModal } = useModalContext();

  const { sendErrorNotification, sendSuccessNotification } = useNotificationContext()

  const uninstallPlugin = async () => {
    try {
      await deactivatePluginInstallation(pluginInstallation)
      sendSuccessNotification({ title: 'Plugin uninstalled!' })
      log(EventKind.PluginUninstalled, { pluginId: pluginInstallation.pluginId, pluginInstallationId: pluginInstallation.id, userId: pluginInstallation?.userId })
      refetch()
      closeModal()
    } catch (e: any) {
      const title = e?.response?.data?.error || 'Something went wrong'
      sendErrorNotification({ title })
    }
  }

  const installPlugin = async () => {
    try {
      await activatePluginInstallation(pluginInstallation)
      sendSuccessNotification({ title: 'Plugin installed!' })
      log(EventKind.PluginInstalled, { pluginId: pluginInstallation.pluginId, pluginInstallationId: pluginInstallation.id, userId: pluginInstallation?.userId })
      refetch()
      closeModal()
    } catch (e: any) {
      const title = e?.response?.data?.error || 'Something went wrong'
      sendErrorNotification({ title })
    }
  }

  const status = getPluginInstallationStatus(pluginInstallation)
  
  return (
    <Modal title="Edit plugin installation" onClose={closeModal}>
      <H4>Status</H4>
      <div style={{marginTop: "-0.1rem", marginBottom: "0.5rem"}}><PluginInstallationStatusBadge status={status}>{status}</PluginInstallationStatusBadge></div>
      <H4>Plugin Installation ID</H4>
      <Input value={pluginInstallation.id} disabled style={{marginTop: 0}} />
      <H4>Plugin Installation UUID</H4>
      <Input value={pluginInstallation.uuid} disabled style={{marginTop: 0}} />
      <H4>User ID</H4>
      <Input value={pluginInstallation.userId} disabled style={{marginTop: 0}} />
      <H4>This will grant the plugin</H4>
      <ul style={{ marginTop: 0}}>
        {getHumanReadablePluginPermissions(plugin)?.map(permission => <li>{permission}</li>)}
      </ul>
      {status === PluginInstallationStatus.Installed && <Button danger onClick={uninstallPlugin}>Uninstall</Button>}
      {status === PluginInstallationStatus.Uninstalled && <Button onClick={installPlugin}>Install</Button>}
    </Modal>
  );
}

export default EditPluginInstallationModal;