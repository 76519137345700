import { StyledButton, StyledButtonProps, StyledLinkButton, StyledNavLinkButton } from './index.styles'

import Icon from '@mdi/react'
import React from 'react'

interface ButtonProps extends StyledButtonProps {
  iconName?: string
  iconColor?: string
  iconSize?: number
  children?: any
  [x: string]: any
}

const InnerButton = ({ iconName, iconColor, iconSize, children }: ButtonProps) => {
  return (
    <React.Fragment>
      {iconName && <Icon className="mainIcon" color={iconColor} path={iconName} size={iconSize || 1}></Icon>}
      <span style={ iconName && children ? { marginLeft: '.3rem' } : { marginLeft: '0rem' }}>{children}</span>
    </React.Fragment>
  )
}

export const Button = ({ iconName, iconColor, children, iconSize, ...props }: ButtonProps) => {
  return (
    <StyledButton {...props}>
      <InnerButton children={children} iconColor={iconColor} iconName={iconName} iconSize={iconSize} />
    </StyledButton>
  )
}

export const LinkButton = ({ iconName, iconColor, children, ...props }: ButtonProps) => {
  return (
    <StyledLinkButton {...props}>
      <InnerButton children={children} iconColor={iconColor} iconName={iconName} />
    </StyledLinkButton>
  )
}

export const NavLinkButton = ({ iconName, iconColor, iconSize, children, ...props }: ButtonProps) => {
  return (
    <StyledNavLinkButton {...props}>
      <InnerButton children={children} iconColor={iconColor} iconName={iconName} iconSize={iconSize} />
    </StyledNavLinkButton>
  )
}
