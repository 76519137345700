import styled from 'styled-components'
import { colors } from 'src/shared/colors'

export const LabelWrapper = styled.label<{ small?: boolean }>`
    padding: 0;
    min-height: ${props => (props.small ? '1.875rem' : '3rem')};
    border-radius: 10px;
    font-size: 1rem;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bold;
    transition: all .25s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: relative;

    svg, span {
        position: relative;
        z-index: 10;
    }

    :hover {
        cursor: pointer;

        :after {
            background: ${colors.GREY_100};
        }
    }

    :after {
        content: "";
        display: block;
        width: calc(100% + 1rem);
        position: absolute;
        top: 0.25rem;
        left: -0.5rem;
        height: calc(100% - 0.5rem);
        z-index: 0;
        border-radius: 50px;
        transition: all 0.2s ease;
    }
`

export const LabelText = styled.span`
    margin: 0 .25rem;
    color: ${colors.BLACK};
`
