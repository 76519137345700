import React from 'react'
import { Tab } from '../Text/'
import { getAccountRoutes } from 'src/shared/routes'
import { TabsContainer, TabList, TabBorder, ActiveBar } from './index.styles'

function AccountTabs (props) {
  const ACCOUNT_ROUTES_CONFIG = getAccountRoutes()
  const isActive = (path) => {
    return (path === window.location.pathname)
  }

  return (
    <TabsContainer {...props}>
      <TabList>
        {ACCOUNT_ROUTES_CONFIG.map((item, index) => (
          <li key={index}>
            <Tab active={isActive(item.to)} to={item.to} text>{item.title}{isActive(item.to) ? <ActiveBar data-testid='active-bar' /> : <div/>}</Tab>
          </li>
        ))}
      </TabList>
      <TabBorder/>
    </TabsContainer>
  )
}

export default AccountTabs
