import { useEffect, useState } from "react"
import { PaginatedResponse, Plugin, fetchPlugins } from "src/shared/api"
import LoadingSpinner from "src/shared/components/LoadingSpinner"
import { PluginContainer, PluginLogo, PluginStatusBadge } from "./index.styles"
import PluginsPageLayout from "src/shared/components/PluginsPageLayout"
import { Link } from "react-router-dom"
import { getPluginStatus } from "../utils"
import { Button } from "src/shared/components/Button"
import { useModalContext } from "src/shared/context/ModalProvider"
import NewPluginModal from "./NewPluginModal"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { checkIsPluginAdmin } from 'src/shared/aws'

function PluginList () {
  const [loading, setLoading] = useState(true)
  const [plugins, setPlugins] = useState<PaginatedResponse<Plugin> | null>(null)
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const [isPluginAdmin, setIsPluginAdmin] = useState(false)
  const { openModal } = useModalContext()

  useEffect(() => {
    const fetchApiRequest = async () => {
      const plugins = await fetchPlugins()
      setPlugins(plugins)
      setLoading(false)
    }
    fetchApiRequest()
  }, [shouldRefetch])

  useEffect(() => {
    const getIsPluginAdmin = async () => {
      setIsPluginAdmin(await checkIsPluginAdmin())
    }
    getIsPluginAdmin()
  }, [])

  const loadMore = async () => {
    const lastId = plugins?.data[plugins?.data.length - 1].id
    setLoading(true)
    const result = await fetchPlugins(lastId)
    setPlugins({ data: [...(plugins?.data ?? []), ...result.data], totalCount: result.totalCount, hasMore: result.hasMore })
    setLoading(false)
  }
  
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: plugins?.hasMore ?? false,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  const refetch = () => {
    setShouldRefetch(!shouldRefetch)
  }

  if ((loading && plugins?.data.length === 0) || (loading && !plugins)) {
    return <LoadingSpinner />
  }

  if (!plugins?.data) {
    return null
  }

  return (
    <PluginsPageLayout ref={rootRef} actions={isPluginAdmin && [<Button onClick={() => openModal(<NewPluginModal refetch={refetch} />)}>New plugin</Button>]}>
      <div style={{display: "flex", alignItems: "center", marginTop: "1.5rem"}}>
        <div style={{fontSize: "1.2rem", paddingLeft: "0.8rem", padding: "0.5rem", fontWeight: "bold"}}>ID</div>
        <div style={{fontSize: "1.2rem", paddingLeft: "1.6rem", padding: "0.5rem", fontWeight: "bold", flex: 1}}>Plugin Name</div>
        <div style={{fontSize: "1.2rem", paddingLeft: 0, padding: "0.5rem", fontWeight: "bold", flex: 1, textAlign: "right"}}>Created On / Status</div>
      </div>
      {plugins?.data?.map((plugin) => (
        <Link key={plugin.id} to={`/plugins/${plugin.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
          <PluginContainer>
            <div style={{display: "flex", alignItems: "center"}}>
              <PluginLogo>{plugin.id}</PluginLogo>
              <div style={{display: "flex", flexDirection: "column", marginLeft: "1rem"}}>
                <div style={{fontSize: '1.2rem', fontWeight: 'bold'}}>{plugin.name || "Untitled plugin"}</div>
              </div>
            </div>
            <div style={{flex: 1, marginRight: "1rem", textAlign: "right"}}>{plugin.createdOn}</div>
            <PluginStatusBadge status={getPluginStatus(plugin)}>{getPluginStatus(plugin)}</PluginStatusBadge>
          </PluginContainer>
        </Link>
      ))}
      {(loading || plugins?.hasMore) && <div ref={sentryRef}><LoadingSpinner  /></div>}
    </PluginsPageLayout>
  )
}

export default PluginList
