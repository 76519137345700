import React, { useEffect, useState } from 'react'
import { getCognitoEmailFromUser, getFullNameFromUser, getInitialsFromUser, getTalkItUser, getTalkItUserId } from 'src/shared/extractors'
import { useAuthContext } from 'src/shared/context/AuthProvider'
import { LinesSection, Section, SubSection, Column, Divider } from './index.styles'
import { P2, H3, Caption, B1, AnchorLink } from 'src/shared/components/Text'
import { Button } from 'src/shared/components/Button'
import ButtonGroup from 'src/shared/components/ButtonGroup'
import { mdiExitToApp, mdiEye } from '@mdi/js'
import Avatar from 'src/shared/components/Avatar'
import Container from 'src/shared/components/Container'
import { formatPhoneNumber } from 'src/shared/formatters'
import { colors } from 'src/shared/colors'
import AccountPageLayout from 'src/shared/components/AccountPageLayout'
import { Auth } from 'aws-amplify'
import { useNotificationContext } from 'src/shared/context/NotificationProvider'

function AccountOverview () {
  const { signOut, currentUser } = useAuthContext()
  const { primaryUserEmail, primaryUserPhone } = getTalkItUser(currentUser)
  const [jwt, setJwt] = useState('')
  const [revealJwt, setRevealJwt] = useState(false)
  const { sendSuccessNotification } = useNotificationContext()


  useEffect(() => {
    async function getJwt () {
      setJwt((await Auth.currentSession()).getIdToken().getJwtToken())
    }
    getJwt()
  }, [])

  const copyJwtToClipboard = async (e) => {
    try {
      e.target.select()
      await navigator.clipboard.writeText(jwt);
      sendSuccessNotification({ title: 'Copied to clipboard' })
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  return (
    <AccountPageLayout>
      <LinesSection>
        <Avatar email={getCognitoEmailFromUser(currentUser)} fullName={getFullNameFromUser(currentUser)} initials={getInitialsFromUser(currentUser)} />
      </LinesSection>
      <Section>
        <Divider/>
        <Column>
          <SubSection>
            <H3>Profile</H3>
            <Container>
              <B1>Full Name</B1>
              <P2 style={{ marginBottom: '1rem' }}>{getFullNameFromUser(currentUser)}</P2>
              {primaryUserEmail.email_address && (
                <React.Fragment>
                  <B1>Account Email</B1>
                  <P2 style={{ marginBottom: '1rem' }}>{primaryUserEmail.email_address}</P2>
                </React.Fragment>
              )}
              {primaryUserPhone.number && (
                <React.Fragment>
                  <B1>Primary Phone</B1>
                  <P2 style={{ marginBottom: '1rem' }}>{formatPhoneNumber(primaryUserPhone.number)}</P2>
                </React.Fragment>
              )}
              <B1>User ID</B1>
              <P2>{getTalkItUserId(currentUser)}</P2>
            </Container>
          </SubSection>
          <SubSection>
            <H3>Developer Key</H3>
            <Caption style={{marginBottom: "1.25rem"}}>Your Developer Key is used to authenticate with certain portions of the ConnectIt+ API. Learn more about how to use this key in our documentation you can find here: <AnchorLink href="https://docs.mobileassistant.us/">docs.mobileassistant.us</AnchorLink></Caption>
            {revealJwt && <textarea readOnly style={{ width: "100%", height: "200px", marginBottom: "1rem", borderRadius: '10px', padding: '0.75rem' }} onClick={copyJwtToClipboard} value={jwt} />}
            <ButtonGroup width="full">
              <Button data-testid="reveal" iconColor={colors.BLUE} iconName={mdiEye} onClick={() => setRevealJwt(!revealJwt)} inverse>{revealJwt ? "Hide" : "Reveal"} JWT</Button>
            </ButtonGroup>
          </SubSection>
          <SubSection>
            <H3>Security</H3>
            <Caption>Manage your account security by taking action below. If you need more help, contact us.</Caption>
            <ButtonGroup width="full">
              <Button data-testid="signOut" iconColor={colors.BLUE} iconName={mdiExitToApp} onClick={signOut} secondary>Sign out</Button>
            </ButtonGroup>
          </SubSection>
        </Column>
      </Section>
    </AccountPageLayout>
  )
}

export default AccountOverview
