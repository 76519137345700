import { useEffect, useState } from "react"
import { PaginatedResponse, Plugin, PluginInstallation, fetchPlugin, fetchPluginInstallations } from "src/shared/api"
import LoadingSpinner from "src/shared/components/LoadingSpinner"
import PluginsPageLayout from "src/shared/components/PluginsPageLayout"
import { useParams } from "react-router-dom"
import { Button } from "src/shared/components/Button"
import { PluginInstallationContainer, PluginInstallationStatusBadge } from "./index.styles"
import Input from "src/shared/components/Input"
import NewPluginInstallationModal from "./NewPluginInstallationModal"
import { useModalContext } from "src/shared/context/ModalProvider"
import { getPluginInstallationStatus } from "../utils"
import EditPluginInstallationModal from "./EditPluginInstallationModal"
import SyncPluginInstallationModal from "./SyncPluginInstallationModal"
import { downloadAsCsv } from "./utils"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { checkIsPluginAdmin } from 'src/shared/aws'

function PluginInstallations () {
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(true)
  const [plugin, setPlugin] = useState<Plugin | null>(null)
  const [pluginInstallations, setPluginInstallations] = useState<PaginatedResponse<PluginInstallation> | null>(null)
  const [search, setSearch] = useState("")
  const { openModal } = useModalContext()
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const [isPluginAdmin, setIsPluginAdmin] = useState(false)

  useEffect(() => {
    const fetchApiRequest = async () => {
      const pluginInstallations = await fetchPluginInstallations(id)
      const plugin = await fetchPlugin(id)
      setPluginInstallations(pluginInstallations)
      setPlugin(plugin)
      setLoading(false)
    }
    fetchApiRequest()
  }, [id, shouldRefetch])

  useEffect(() => {
    const getIsPluginAdmin = async () => {
      setIsPluginAdmin(await checkIsPluginAdmin())
    }
    getIsPluginAdmin()
  }, [])

  const loadMore = async () => {
    const lastId = pluginInstallations?.data[pluginInstallations?.data.length - 1].id
    setLoading(true)
    const result = await fetchPluginInstallations(id, lastId)
    setPluginInstallations({ data: [...(pluginInstallations?.data ?? []), ...result.data], totalCount: result.totalCount, hasMore: result.hasMore })
    setLoading(false)
  }
  
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: pluginInstallations?.hasMore ?? false,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  const refetch = () => {
    setShouldRefetch(!shouldRefetch)
  }

  if ((loading && pluginInstallations?.data.length === 0) || (loading && !pluginInstallations)) {
    return <LoadingSpinner />
  }

  if (!plugin || !pluginInstallations?.data) {
    return null
  }

  const filteredPluginInstallations = search ? 
    pluginInstallations?.data.filter(pluginInstallation => 
      search === String(pluginInstallation.userId) || 
      search === pluginInstallation.uuid ||
      search === pluginInstallation.emailAddress ||
      search === pluginInstallation.firstName ||
      search === pluginInstallation.lastName ||
      search === `${pluginInstallation.firstName} ${pluginInstallation.lastName}`
    )
    :
    pluginInstallations?.data

  const makeActions = (plugin: Plugin) => {
    if (!isPluginAdmin) {
      return []
    }
    if (plugin.crmIntegrationId) {
      return [
        <Button inverse onClick={() => openModal(<SyncPluginInstallationModal plugin={plugin} refetch={refetch} />)}>Sync installations</Button>,
        <Button onClick={() => openModal(<NewPluginInstallationModal plugin={plugin} refetch={refetch} />)}>New installation</Button>
      ]
    }
    return [
      <Button onClick={() => openModal(<NewPluginInstallationModal plugin={plugin} refetch={refetch} />)}>New installation</Button>
    ]
  }


  return (
    <PluginsPageLayout ref={rootRef} plugin={plugin} backTo="/plugins" actions={makeActions(plugin)}>
      <div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
        <Input placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
        {pluginInstallations?.data.length > 0 && <Button inverse onClick={() => downloadAsCsv(plugin)}>Download CSV</Button>}
      </div>
      {filteredPluginInstallations.length > 0 ? (
        <>
          <div style={{display: "flex", alignItems: "center", marginTop: "1.5rem"}}>
            <div style={{paddingLeft: 0, padding: "0.5rem", fontWeight: "bold", flex: 0.4}}>ID</div>
            <div style={{paddingLeft: 0, padding: "0.5rem", fontWeight: "bold", flex: 0.7}}>User ID</div>
            <div style={{paddingLeft: 0, padding: "0.5rem", fontWeight: "bold", flex: 1.5}}>Name</div>
            <div style={{paddingLeft: 0, padding: "0.5rem", fontWeight: "bold", flex: 3}}>Email</div>
            <div style={{paddingLeft: 0, padding: "0.5rem", fontWeight: "bold", flex: 3}}>Plugin Installation UUID</div>
            <div style={{paddingLeft: 0, padding: "0.5rem", fontWeight: "bold", flex: 1}}>Status</div>
          </div>
          {filteredPluginInstallations.map(pluginInstallation => (
            <PluginInstallationContainer onClick={() => openModal(<EditPluginInstallationModal plugin={plugin} pluginInstallation={pluginInstallation} refetch={refetch} />)}>
              <div style={{paddingLeft: 0, padding: "0.5rem", flex: 0.4}}>{pluginInstallation.id}</div>
              <div style={{paddingLeft: 0, padding: "0.5rem", flex: 0.7}}>{pluginInstallation.userId}</div>
              <div style={{paddingLeft: 0, padding: "0.5rem", flex: 1.5}}>{`${pluginInstallation.firstName} ${pluginInstallation.lastName}`}</div>
              <div style={{paddingLeft: 0, padding: "0.5rem", flex: 3}}>{pluginInstallation.emailAddress}</div>
              <div style={{paddingLeft: 0, padding: "0.5rem", flex: 3}}>{pluginInstallation.uuid}</div>
              <div style={{paddingLeft: 0, padding: "0.5rem", flex: 1}}><PluginInstallationStatusBadge status={getPluginInstallationStatus(pluginInstallation)}>{getPluginInstallationStatus(pluginInstallation)}</PluginInstallationStatusBadge></div>
            </PluginInstallationContainer>
          ))}
        </>
      ) : (
        <div style={{ marginTop: "1.5rem" }}>No plugin installations found.</div>
      )}
      {(loading || pluginInstallations?.hasMore) && <div ref={sentryRef}><LoadingSpinner /></div>}
    </PluginsPageLayout>
  )
}

export default PluginInstallations
