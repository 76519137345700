import { Plugin, PluginInstallation, fetchAllPluginInstallations } from "src/shared/api";
import { getPluginInstallationStatus } from "../utils";

const downloadAsCsv = async (plugin: Plugin) => {
  const reponse = await fetchAllPluginInstallations(plugin.id)
  const pluginInstallations = reponse.data;
  const headerRow = ["User ID", "Name", "Email", "Plugin Installation UUID", "Status"];
  const csvRows = [headerRow, ...pluginInstallations.map(makeCsvRow)];
  const csvContent = csvRows.map(e => e.join(",")).join("\n");
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = `${getFormattedDate()}_plugin_${plugin.id}_installations.csv`;
  link.click();
}

const getFormattedDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return "" + year + month + day;
}

const makeCsvRow = (pluginInstallation: PluginInstallation): string[] => {
  return [
    String(pluginInstallation.userId),
    `${pluginInstallation.firstName} ${pluginInstallation.lastName}`,
    pluginInstallation.emailAddress,
    pluginInstallation.uuid,
    getPluginInstallationStatus(pluginInstallation)
  ]
}

export {
  downloadAsCsv
}