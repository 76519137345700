import { useState } from "react";
import { createPlugin } from "src/shared/api";
import Input from "src/shared/components/Input";
import Modal from "src/shared/components/Modal";
import { P2 } from "src/shared/components/Text";
import { useModalContext } from "src/shared/context/ModalProvider";
import { useNotificationContext } from "src/shared/context/NotificationProvider";
import { EventKind } from "src/shared/events";
import { log } from "src/shared/logger";

type PluginValues = {
  userId: string
  name: string
  description: string
}

interface NewPluginModalProps {
  refetch: () => void
}

function NewPluginModal({ refetch }: NewPluginModalProps) {
  const { closeModal } = useModalContext();
  const [values, setValues] = useState<PluginValues>({
    userId: '',
    name: '',
    description: ''
  })
  const [errors, setErrors] = useState<PluginValues>({
    userId: '',
    name: '',
    description: ''
  })

  const defaultPermissions = {
    "user": "read",
    "transcript": "read",
  }

  const { sendErrorNotification, sendSuccessNotification } = useNotificationContext()
  const [loading, setLoading] = useState(false)

  const create = async () => {
    const nextErrors: PluginValues = {
      userId: '',
      name: '',
      description: ''
    }

    if (!values.userId) {
      nextErrors.userId = "User ID is required"
    }

    if (isNaN(parseInt(values.userId))) {
      nextErrors.userId = "User ID must be a number"
    }

    if (!values.name) {
      nextErrors.name = "Plugin name is required"
    }

    if (!values.description) {
      nextErrors.description = "Plugin description is required"
    }

    setErrors(nextErrors)
    
    if (!Object.entries(nextErrors).some(([_, value]) => value)) {
      try {
        setLoading(true)
        const plugin = await createPlugin({ userId: parseInt(values.userId), name: values.name, description: values.description, permissions: defaultPermissions })
        sendSuccessNotification({ title: 'Plugin created!' })
        log(EventKind.PluginCreated, { pluginId: plugin?.id })
        refetch()
        closeModal()
      } catch (e) {
        sendErrorNotification({ title: 'Error creating plugin' })
      } finally {
        setLoading(false)
      }
    }
  }

  const updateValues = (key: keyof PluginValues, value: string) => {
    setValues({ ...values, [key]: value })
    setErrors({ ...errors, [key]: '' })
  }

  return (
    <Modal title="New plugin" onClose={closeModal} dialogue={{ primary: { onClick: create, title: "Create plugin", loading }, secondary: { onClick: closeModal, title: "Cancel" }}}>
      <P2>Enter the details of your new plugin below.</P2>
      <Input label="Owner User ID" onChange={(e) => updateValues("userId", e.target.value)} value={values.userId} error={errors.userId} />
      <Input label="Plugin name" onChange={(e) => updateValues("name", e.target.value)} value={values.name} error={errors.name} />
      <Input label="Plugin description" onChange={(e) => updateValues("description", e.target.value)} value={values.description} error={errors.description} />
    </Modal>
  );
}

export default NewPluginModal;