import styled, { css } from 'styled-components'
import { Link, NavLink } from 'react-router-dom'
import { colors } from 'src/shared/colors'

export interface StyledButtonProps {
  small?: boolean
  secondary?: boolean
  fab?: boolean
  inverse?: boolean
  text?: boolean
  disabled?: boolean
  inline?: boolean
  banner?: boolean
  danger?: boolean
}

const sharedButtonStyles = css<StyledButtonProps>`
  align-items: center;
  font-size: 0.9rem;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  outline: none;
  text-align: center;
  padding: ${props => (props.small ? '.35rem' : '0.5rem 1.5rem')};
  background-color: ${props => (props.secondary ? colors.BLACK : colors.BLUE)};
  color: ${colors.WHITE};
  border-radius: 40px;
  text-decoration: none;
  display: flex;
  white-space: nowrap;
  justify-content: center;
  transition: all .25s ease;
  box-size: bordear-box;
  min-height: ${props => (props.small ? '1.875rem' : '2rem')};
  height: ${props => (props.small ? '2rem' : '2rem')};
  min-width: 3rem;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  :hover {
    cursor: pointer;
    color: ${colors.WHITE};
  }

  :active {
    opacity: ${props => (props.fab ? '1' : '.6')}
  }

  :visited {
      color: ${colors.WHITE};
    }

  ${props => props.inverse && css<StyledButtonProps>`
    color: ${colors.BLACK};
    background: ${colors.GREY_200};

    :hover {
      color: ${colors.BLUE};
    }

    :visited {
      color: ${colors.BLACK};
    }
  `}

  ${props => props.text && css<StyledButtonProps>`
    color: ${colors.BLACK};
    background: ${colors.TRANSPARENT};

    :hover {
      color: ${colors.BLUE};
    }

    :visited {
      color: ${colors.BLACK};
    }
  `}

  ${props => props.inline && css<StyledButtonProps>`
    color: ${colors.BLACK};
    background: ${colors.TRANSPARENT};
    padding: 0;
    position: relative;

    span {
      position: relative;
      z-index: 10;
    }

    :after {
      content: "";
      display: block;
      width: calc(100% + 1.5rem);
      position: absolute;
      top: 0.25rem;
      left: -0.75rem;
      height: calc(100% - 0.5rem);
      z-index: 0;
      border-radius: 50px;
      transition: all 0.2s ease;
    }

    :hover {
      color: ${colors.BLACK};
      background: ${colors.TRANSPARENT};

      :after {
          background: ${colors.GREY_100};
      }
    }

    :visited {
      color: ${colors.BLACK};
    }
  `}

  ${props => props.banner && css<StyledButtonProps>`
    color: ${colors.BLUE};
    background: ${colors.TRANSPARENT};
    padding: 0;

    :hover {
      color: ${colors.BLUE};
      background: ${props => props.disabled ? 'none' : colors.GREY_100};
    }

    :visited {
      color: ${colors.BLUE};
    }
  `}

  ${props => props.disabled && css<StyledButtonProps>`
    opacity: .3 !important;
        
    :hover {
      cursor: default;
    }

    :active {
      opacity: .3 !important;
    }
  `}

  ${props => props.danger && css<StyledButtonProps>`
    color: ${colors.WHITE};
    background: ${colors.RED};
    
    :hover {
      color: ${colors.WHITE};
    }
  `}
`

export const StyledButton = styled.a`
  ${props => sharedButtonStyles}
`

export const StyledLinkButton = styled(({ text, inverse, inline, small, disabled, secondary, isActive, banner, to, ...restProps }) => <Link to={to} {...restProps} />)`
  ${props => sharedButtonStyles}
`

export const StyledNavLinkButton = styled(({ text, inverse, inline, small, disabled, secondary, isActive, banner, to, ...restProps }) => <NavLink to={to} activeClassName={'active'} {...restProps} />)`
  ${props => sharedButtonStyles}
`
