import { useState } from "react";
import { getHumanReadablePluginPermissions } from "src/plugins/utils";
import { Plugin, createPluginInstallation } from "src/shared/api";
import Input from "src/shared/components/Input";
import Modal from "src/shared/components/Modal";
import { H4 } from "src/shared/components/Text";
import { useModalContext } from "src/shared/context/ModalProvider";
import { useNotificationContext } from "src/shared/context/NotificationProvider";
import { EventKind } from "src/shared/events";
import { log } from "src/shared/logger";

type PluginInstallationValues = {
  userId: string
}

type PluginInstallationErrors = {
  [key in keyof PluginInstallationValues]: string
}

interface NewPluginInstallationModalProps {
  plugin: Plugin,
  refetch: () => void
}

function NewPluginInstallationModal({ plugin, refetch }: NewPluginInstallationModalProps) {
  const { closeModal } = useModalContext();
  const [values, setValues] = useState<PluginInstallationValues>({
    userId: ''
  })
  const [errors, setErrors] = useState<PluginInstallationErrors>({
    userId: ''
  })

  const { sendErrorNotification, sendSuccessNotification } = useNotificationContext()
  const [loading, setLoading] = useState(false)

  const installPlugin = async () => {
    const nextErrors: PluginInstallationErrors = {
      userId: ''
    }

    if (!values.userId) {
      nextErrors.userId = "User ID is required"
    }

    if (isNaN(parseInt(values.userId))) {
      nextErrors.userId = "User ID must be a number"
    }

    setErrors(nextErrors)
    
    if (!Object.entries(nextErrors).some(([_, value]) => value)) {
      try {
        setLoading(true)
        const pluginInstallation = await createPluginInstallation({
          pluginId: plugin.id,
          userId: parseInt(values.userId),
        })
        sendSuccessNotification({ title: 'Plugin installed!' })
        log(EventKind.PluginInstalled, { pluginId: plugin.id, pluginInstallationId: pluginInstallation?.id, userId: pluginInstallation?.userId })
        refetch()
        closeModal()
      } catch (e: any) {
        const title = e?.response?.data?.error || 'Something went wrong'
        sendErrorNotification({ title })
      } finally {
        setLoading(false)
      }
    }
  }

  const updateValues = (key: keyof PluginInstallationValues, value: string) => {
    setValues({ ...values, [key]: value })
    setErrors({ ...errors, [key]: '' })
  }

  return (
    <Modal title="New plugin installation" onClose={closeModal} dialogue={{ primary: { onClick: installPlugin, loading }, secondary: { onClick: closeModal }}}>
      <Input label="User ID" onChange={(e) => updateValues("userId", e.target.value)} value={values.userId} error={errors.userId} />
      <H4>This will grant the plugin</H4>
      <ul style={{ marginTop: 0}}>
        {getHumanReadablePluginPermissions(plugin)?.map(permission => <li>{permission}</li>)}
      </ul>
    </Modal>
  );
}

export default NewPluginInstallationModal;