const getPhoneNumberDigits = (phoneNumber) => {
  return phoneNumber.replace(/[^0-9]/g, '')
}

const getIsUserAdmin = (currentUser) => {
  return getTalkItUser(currentUser)?.isAdmin
}

const getCognitoId = (currentUser) => {
  return currentUser?.attributes?.sub
}

const getCognitoEmailFromUser = (currentUser) => {
  return currentUser?.attributes?.email
}

const getDictatorIdFromUser = (currentUser) => {
  return getTalkItUser(currentUser)?.dictatorId
}

const getFullNameFromUser = (currentUser) => {
  const { firstName, lastName } = getTalkItUser(currentUser)
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
}

const getInitialsFromUser = (currentUser) => {
  const { firstName, lastName } = getTalkItUser(currentUser)
  if (firstName && lastName) {
    return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`
  }
}

const getTalkItUserId = (currentUser) => {
  return currentUser?.talkItAttributes?.user?.id
}

const getInitialsFromName = (name) => {
  if (isStringNullOrEmpty(name)) {
    return ''
  }
  const splitName = name.split(' ', 2)
  if (splitName.length === 2) {
    return (splitName[0][0] + splitName[1][0]).toUpperCase()
  }
  return splitName[0][0].toUpperCase()
}

const isStringNullOrEmpty = (string) => {
  if (typeof string !== 'string') {
    return true
  }
  if (!string) {
    return true
  }
  if (!string.trim()) {
    return true
  }
  return false
}

const getTalkItUser = (currentUser) => {
  const {
    talkItAttributes: {
      user: {
        first_name: firstName = '',
        last_name: lastName = '',
        billing_lines_cap_for_period: linesAvailable  = 0,
        billing_lines_used: linesUsed = 0,
        primary_user_phone: primaryUserPhone = {},
        primary_user_email: primaryUserEmail = {},
        cognito_id: cognitoId = '',
        team_id: teamId = '',
        crm_name: crmName = '',
        is_trial_user: isTrialUser = false,
        is_inactive: isInactive = true,
        is_billing_manager: isAdmin = false,
        user_preferences: userPreferences = {},
        legal = {}
      } = {}
    } = {}
  } = currentUser || {}
  const { dictator_id: dictatorId } = userPreferences || {}

  return {
    firstName,
    lastName,
    linesAvailable,
    linesUsed,
    primaryUserEmail,
    primaryUserPhone,
    cognitoId,
    teamId,
    crmName,
    dictatorId,
    isTrialUser,
    isInactive,
    isAdmin,
    userPreferences,
    legal
  }
}

const getCrmFromUser = (currentUser) => {
  const { crmName } = getTalkItUser(currentUser)
  return crmName
}

const getNameParts = (fullName) => {
  const trimmedName = fullName.trim()

  return {
    firstName: trimmedName.substr(0, trimmedName.indexOf(' ')),
    lastName: trimmedName.substr(trimmedName.indexOf(' ') + 1)
  }
}

const getEmailFromLocationState = (props) => {
  return props?.location?.state?.email || ''
}

const getParamsFromLocationSearch = (props): { [key: string]: string } => {
  const rawParams = new URLSearchParams(props?.location?.search)
  const decodedParams = {}

  rawParams.forEach((value, key) => {
    Object.assign(decodedParams, { [key]: value })
  })

  return decodedParams
}

export {
  getNameParts,
  getPhoneNumberDigits,
  getEmailFromLocationState,
  getParamsFromLocationSearch,
  getCognitoId,
  getCognitoEmailFromUser,
  getDictatorIdFromUser,
  getFullNameFromUser,
  getInitialsFromUser,
  getInitialsFromName,
  getTalkItUser,
  getCrmFromUser,
  getIsUserAdmin,
  getTalkItUserId
}
