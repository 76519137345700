import { useEffect, useState } from "react"
import { Plugin, PluginStatus, fetchPlugin } from "src/shared/api"
import LoadingSpinner from "src/shared/components/LoadingSpinner"
import PluginsPageLayout from "src/shared/components/PluginsPageLayout"
import { useParams } from "react-router-dom"
import { H4 } from "src/shared/components/Text"
import { getHumanReadablePluginPermissions, getPluginStatus } from "../utils"
import { PluginStatusBadge } from "../PluginList/index.styles"
import { Button } from "src/shared/components/Button"
import EditPluginModal from "./EditPluginModal"
import { useModalContext } from "src/shared/context/ModalProvider"
import { checkIsPluginAdmin } from 'src/shared/aws'

function PluginDetail () {
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(true)
  const [plugin, setPlugin] = useState<Plugin | null>(null)
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const [isPluginAdmin, setIsPluginAdmin] = useState(false)

  const { openModal } = useModalContext()

  const statusDescriptions: Record<PluginStatus, string> = {
    [PluginStatus.Published]: "This plugin is available for any Talk It user to install.",
    [PluginStatus.Disabled]: "This plugin can't access any user data or be installed.",
    [PluginStatus.Unlisted]: "This plugin can only be installed by the plugin owner.",
  }

  useEffect(() => {
    const fetchApiRequest = async () => {
      const plugin = await fetchPlugin(id)
      setPlugin(plugin)
      setLoading(false)
    }
    fetchApiRequest()
  }, [id, shouldRefetch])

  useEffect(() => {
    const getIsPluginAdmin = async () => {
      setIsPluginAdmin(await checkIsPluginAdmin())
    }
    getIsPluginAdmin()
  }, [])

  const refetch = () => {
    setShouldRefetch(!shouldRefetch)
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (!plugin) {
    return <div>Something went wrong!</div>
  }

  const status = getPluginStatus(plugin)

  return (
    <PluginsPageLayout plugin={plugin} backTo="/plugins" actions={isPluginAdmin && [<Button onClick={() => openModal(<EditPluginModal plugin={plugin} refetch={refetch} />)}>Edit plugin</Button>]}>
      {status && (
        <>
        <H4>Status</H4>
        <div style={{marginBottom: "2.5rem"}}>
          <PluginStatusBadge status={status}>{status}</PluginStatusBadge>
          <div style={{ opacity: 0.5, fontSize: "0.85rem", marginTop: "0.5rem" }}>{statusDescriptions[status]}</div>
        </div>
        </>
      )}
      <H4>Description</H4>
      <div style={{ marginBottom: "2.5rem"}}>{plugin.description}</div>
      {plugin.crmIntegrationId && (
        <>
          <H4>CRM Integration ID</H4>
          <div style={{ marginBottom: "2.5rem"}}>{plugin.crmIntegrationId}</div>
        </>
      )}
      <H4>Plugin permissions (where installed)</H4>
      <ul style={{ marginTop: 0, marginBottom: "2.5rem" }}>
        {getHumanReadablePluginPermissions(plugin)?.map(permission => <li>{permission}</li>)}
      </ul>
      <H4>Created On</H4>
      <div>{plugin.createdOn}</div>
    </PluginsPageLayout>
  )
}

export default PluginDetail
