import { useState } from "react";
import { Plugin, syncPluginInstallations } from "src/shared/api";
import Modal from "src/shared/components/Modal";
import { P2 } from "src/shared/components/Text";
import { useModalContext } from "src/shared/context/ModalProvider";
import { useNotificationContext } from "src/shared/context/NotificationProvider";
import { EventKind } from "src/shared/events";
import { log } from "src/shared/logger";

interface SyncPluginInstallationModalProps {
  plugin: Plugin,
  refetch: () => void
}

function SyncPluginInstallationModal({ plugin, refetch }: SyncPluginInstallationModalProps) {
  const { closeModal } = useModalContext();
  const { sendErrorNotification, sendSuccessNotification } = useNotificationContext()
  const [loading, setLoading] = useState(false)

  const sync = async () => {
    try {
      setLoading(true)
      await syncPluginInstallations(plugin)
      sendSuccessNotification({ title: 'Plugin installations synced!' })
      log(EventKind.PluginInstallationSynced, { pluginId: plugin.id })
      refetch()
      closeModal()
    } catch (e: any) {
      const title = e?.response?.data?.error || 'Something went wrong'
      sendErrorNotification({ title })
    }
  }

  return (
    <Modal title="Are you sure?" onClose={closeModal} dialogue={{ primary: { onClick: sync, title: "Yes, continue", loading }, secondary: { onClick: closeModal }}}>
      <P2>Do you want to start syncing plugin installations with Patriot CRM?</P2>
    </Modal>
  );
}

export default SyncPluginInstallationModal;