import React, { createContext, useState, useContext } from 'react'
import ReactDOM from 'react-dom'

interface ModalContextProps {
  modal?: React.ReactNode
  openModal: (modal: React.ReactNode) => void
  closeModal: () => void
}

export const ModalContext = createContext<ModalContextProps>({
  modal: null,
  openModal: () => {},
  closeModal: () => {}
})

export const ModalContainer = () => {
  const { modal } = useModalContext()
  return modal ? ReactDOM.createPortal(modal, document.body) : null
}

const ModalProvider = (props) => {
  const [modal, setModal] = useState<React.ReactNode | null>(null)

  const closeModal = () => setModal(null)
  const openModal = (modal: React.ReactNode) => setModal(modal)

  return (
    <ModalContext.Provider
      value={{
        modal,
        openModal,
        closeModal
      }}
      {...props} />
  )
}

export const useModalContext = () => useContext(ModalContext)

export default ModalProvider
