import { Plugin, PluginInstallation, PluginInstallationStatus, PluginStatus } from "src/shared/api"

const getPluginStatus = (plugin?: Plugin): PluginStatus => {
  if (plugin?.disabled) {
    return PluginStatus.Disabled
  }
  if (plugin?.published) {
    return PluginStatus.Published
  }
  return PluginStatus.Unlisted
}

const getPluginInstallationStatus = (pluginInstallation?: PluginInstallation): PluginInstallationStatus => {
  if (pluginInstallation?.deletedOn) {
    return PluginInstallationStatus.Uninstalled
  }
  return PluginInstallationStatus.Installed
}

const getHumanReadablePluginPermissions = (plugin?: Plugin): string[] => {
  try {
    const permissions = plugin?.permissions ?? {}
    const permissionKeys = Object.keys(permissions)
    return permissionKeys.map((key) => {
      return `Access to ${permissions[key]} ${key} records`
    })
  } catch (e) {
    console.error(e)
    return []
  }
}

export {
  getPluginStatus,
  getPluginInstallationStatus,
  getHumanReadablePluginPermissions
}