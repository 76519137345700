import { H1 } from '../Text'
import { Area, Header, Container } from './index.styles'
import { forwardRef } from 'react';

const HistoryPageLayout = forwardRef<HTMLDivElement, React.PropsWithChildren<any>>((props, ref) => {
  return (
    <Area {...props} ref={ref}>
      <Header>
        <H1>History</H1>
      </Header>
      <Container>
        {props.children}
      </Container>
    </Area>
  )
})

export default HistoryPageLayout;