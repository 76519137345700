import React from 'react'
import { ImpulseSpinner } from 'react-spinners-kit'
import { colors } from 'src/shared/colors'
import { SpinnerContainer } from './index.styles'

const LoadingSpinner = ({ ...props }) => {
  return (
    <SpinnerContainer {...props} data-testid="loading-spinner">
      <ImpulseSpinner backColor={props.backColor || colors.WHITE} frontColor={props.frontColor || colors.BLUE} size={props.size || 30} />
    </SpinnerContainer>
  )
}

export default LoadingSpinner
