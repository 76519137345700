import { Link } from 'react-router-dom'
import { H1, Tab } from '../Text'
import { Area, Header, Container } from './index.styles'
import Icon from '@mdi/react'
import { mdiArrowLeft } from '@mdi/js'
import { Plugin } from 'src/shared/api'
import { TabList, TabsContainer, TabBorder, ActiveBar } from '../AccountTabs/index.styles'
import { getPluginDetailRoutes } from 'src/shared/routes'
import { forwardRef } from 'react'

interface PluginsPageLayoutProps {
  children: React.ReactNode
  actions?: React.ReactNode[]
  plugin?: Plugin
  backTo?: string
}

const PluginsPageLayout = forwardRef<HTMLDivElement, React.PropsWithChildren<any>>(({ plugin, children, backTo, actions }: PluginsPageLayoutProps, ref) => {
  const title = plugin ? plugin.name : "Untitled plugin"

  const isActive = (path) => {
    return (path === window.location.pathname)
  }

  return (
    <Area ref={ref}>
      {backTo && <Link to={backTo} style={{display: "flex", alignItems: "center", marginTop: "1rem", marginLeft: "1rem"}}>
        <Icon path={mdiArrowLeft} size={0.8} style={{ marginRight: "0.25rem" }}/>
        Back
      </Link>}
      <Header>
        <H1 style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          {(plugin && title) ? title : "Plugins"}
          <div style={{ display: "flex", gap: "0.5rem" }}>
          {actions && actions.map(action => action)}
          </div>
        </H1>
      </Header>
      {plugin && (
        <TabsContainer>
        <TabList>
          {getPluginDetailRoutes(plugin?.id).map((item, index) => (
            <li key={index}>
              <Tab active={isActive(item.to)} to={item.to} text>{item.title}{isActive(item.to) ? <ActiveBar data-testid='active-bar' /> : <div/>}</Tab>
            </li>
          ))}
        </TabList>
      </TabsContainer>)}
      <TabBorder/>
      <Container style={{marginTop: '1rem'}}>
        {children}
      </Container>
    </Area>
  )
})

export default PluginsPageLayout
